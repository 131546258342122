import React, {useEffect, useRef, useState} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {getCraneHealthCheckData, getCraneStatusData} from 'api/CranesApi';
import {capacityUnits, vfdClasses} from 'constants/dataConstants';
import {DATETIME_FORMAT, TIME_FORMAT_SHORT} from 'constants/uiConstants';
import Loader from 'components/Loader/Loader';
import HealthCheckIndicator from 'components/Other/HealthCheckIndicator';
import {useAppContext} from 'contexts/AppContext';
import {mdash} from 'utils/Ui';
import {arrayToObject, zeroFill} from 'utils/Utils';

const valueStyle = {
    color: '#000',
    fontWeight: 'bold',
    paddingLeft: '3px',
};

export default function CraneStatus({crane}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [healthCheckData, setHealthCheckData] = useState({});
    const [craneStatusData, setCraneStatusData] = useState({});

    let customerName;
    let siteName;
    let locationName;
    let craneSystemName;

    useEffect(() => {
        isMounted.current = true;
        loadHealthCheckData();
        loadCraneStatusData();
        const intervalId = setInterval(loadHealthCheckData, 10000);
        return () => {
            isMounted.current = false;
            clearInterval(intervalId);
        }
    }, []);

    const loadHealthCheckData = () => {
        const onSuccess = response => isMounted.current && setHealthCheckData(response.data);
        getCraneHealthCheckData(crane.id, onSuccess);
    };

    const loadCraneStatusData = () => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setCraneStatusData(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        getCraneStatusData(crane.id, onSuccess, handleApiError, onDone);
    }

    const getRunTime = motionRuntime => {
        const runTime = motionRuntime || {cumulative: {days: 0, seconds: 0}, hourly: {days: 0, seconds: 0}};
        let runTimeCumulative = '00:00';
        let runTimeHourly = '00:00';
        let runTimeHours;
        let runTimeMinutes;
        if (runTime) {
            runTimeHours = Math.floor(runTime.cumulative.seconds / 3600);
            runTimeMinutes = Math.floor((runTime.cumulative.seconds - runTimeHours * 3600) / 60);
            runTimeHours += runTime.cumulative.days * 24;
            runTimeCumulative = zeroFill(runTimeHours, 2) + ':' + zeroFill(runTimeMinutes, 2);
            runTimeHours = Math.floor(runTime.hourly.seconds / 3600);
            runTimeMinutes = Math.floor((runTime.hourly.seconds - runTimeHours * 3600) / 60);
            runTimeHours += runTime.hourly.days * 24;
            runTimeHourly = zeroFill(runTimeHours, 2) + ':' + zeroFill(runTimeMinutes, 2);
        }
        return [runTimeCumulative, runTimeHourly];
    };

    const getStartStop = motionStartStop => {
        let startStopCumulative = 0;
        let startStopHourly = 0;
        if (motionStartStop) {
            startStopCumulative = motionStartStop.cumulative;
            startStopHourly = motionStartStop.hourly;
        }
        return [startStopCumulative, startStopHourly];
    };

    let pageContent;
    if (isLoading) pageContent = <Loader />;
    else {
        const craneDetails = craneStatusData.crane_details || {};
        const customerDetails = craneStatusData.customer_details || {};
        const craneSystemDetails = craneStatusData.crane_system_details;
        const displayDetails = craneStatusData.display_details;

        customerName = customerDetails.customer.customer_name;
        siteName = customerDetails.site.site_name;
        locationName = customerDetails.location.location_name;
        craneSystemName = craneSystemDetails.crane_system_name;

        const vfds = arrayToObject(craneDetails.vfd_config, 'vfd_uuid');
        const motions = arrayToObject(craneDetails.motion_config, 'motion_uuid');
        const installedOnMotions = {};
        Object.entries(motions).map(([motionUuid, motion]) => {
            motion.component_motions.forEach(childMotionUuid => installedOnMotions[childMotionUuid] = motionUuid);
        });

        pageContent = Object.entries(motions).map(([motionUuid, motion]) => {
            const motionVfds = motion.motion_vfd_mapping;
            const vfdUuid = motionVfds.length ? motionVfds[0] : '';
            const vfd = Object.keys(vfds).includes(vfdUuid) ? vfds[vfdUuid] : null;
            const installedOn = installedOnMotions[motionUuid] ? motions[installedOnMotions[motionUuid]].motion_name : mdash;
            const [runTimeCumulative, runTimeHourly] = getRunTime(motion.summary?.run_time);
            const motorAmps = motion.summary?.current || {};
            const [startStopCumulative, startStopHourly] = getStartStop(motion.summary?.starts_stops);
            const motorRpm = motion.summary?.rpm || {};
            const cmaa = motion.summary?.cmaa || {};
            const loadCell = motion.summary?.loadcell || {};
            let updatedOn = mdash;
            let startTimeStr;
            let endTimeStr;
            if (motion.summary?.period_start) startTimeStr = moment.utc(motion.summary?.period_start).local().format(TIME_FORMAT_SHORT);
            if (motion.summary?.period_end) endTimeStr = moment.utc(motion.summary?.period_end).local().format(TIME_FORMAT_SHORT);
            const statusTimestampStr = startTimeStr && endTimeStr ? startTimeStr + ' - ' + endTimeStr : mdash;
            if (motion.timestamp) updatedOn = moment.utc(motion.timestamp).local().format(DATETIME_FORMAT);
            let loadAvg = '0';
            let loadMax = '0';
            if (loadCell) {
                if (Number.parseFloat(loadCell.avg)) loadAvg = Math.round(loadCell.avg);
                if (Number.parseFloat(loadCell.max)) loadMax = Math.round(loadCell.max);
            }
            let rpmAvg = '0.00';
            let rpmMax = '0.00';
            if (motorRpm) {
                if (Number.parseFloat(motorRpm.avg)) rpmAvg = Number.parseFloat(motorRpm.avg).toFixed(2);
                if (Number.parseFloat(motorRpm.max)) rpmMax = Number.parseFloat(motorRpm.max).toFixed(2);
            }
            let ampsAvg = '0.00';
            let ampsMax = '0.00';
            if (motorAmps) {
                if (Number.parseFloat(motorAmps.avg)) ampsAvg = Number.parseFloat(motorAmps.avg).toFixed(2);
                if (Number.parseFloat(motorAmps.max)) ampsMax = Number.parseFloat(motorAmps.max).toFixed(2);
            }
            let cmaaAvg = cmaa?.current || mdash;
            return (
                <Col md="12" key={motionUuid}>
                    <Card>
                        <Card.Header>
                            <div className="card-category text-title" style={{fontSize: '18px', fontWeight: 'bold'}}>
                                {motion.motion_name}
                                <p className="card-category" style={{float: 'right', fontSize: '13px'}}>updated on <span style={valueStyle}>{updatedOn}</span></p>
                            </div>
                        </Card.Header>
                        <Card.Body style={{paddingTop: 0}}>
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                    <p className="card-category" style={{float: 'left', fontSize: '13px'}}>CMAA class: <span style={valueStyle}>{cmaaAvg}</span></p>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    <p className="card-category" style={{float: 'right', fontSize: '13px'}}>For the hour: <span style={valueStyle}>{statusTimestampStr}</span></p>
                                </Col>
                                <Col lg="3" md="3" sm="6">
                                    <p className="card-category">Motion type <span style={valueStyle}>{displayDetails.motion_type[motion.motion_type]}</span></p>
                                </Col>
                                <Col lg="3" md="3" sm="6">
                                    <p className="card-category">Capacity <span style={valueStyle}>{craneDetails.crane_capacity + ' ' + displayDetails.capacity_unit[craneDetails.capacity_unit]}</span></p>
                                </Col>
                                <Col lg="3" md="3" sm="6">
                                    <p className="card-category">VFD class <span style={valueStyle}>{vfd ? vfdClasses[vfd.vfd_class] : mdash}</span></p>
                                </Col>
                                <Col lg="3" md="3" sm="6">
                                    <p className="card-category">Installed on <span style={valueStyle}>{installedOn}</span></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" md="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Motor speed (RPM)</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Max</p>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <p className="card-category">Avg</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{rpmMax}</Card.Title>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{rpmAvg}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Motor current (Amps)</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Max</p>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <p className="card-category">Avg</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{ampsMax}</Card.Title>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{ampsAvg}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Load (lbs)</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Max</p>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <p className="card-category">Avg</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{loadMax}</Card.Title>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{loadAvg}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="4" md="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Run time (Hrs:Min)</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">For the Hour</p>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <p className="card-category">Cumulative</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{runTimeHourly}</Card.Title>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{runTimeCumulative}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">Start/Stops</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <p className="card-category">For the Hour</p>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <p className="card-category">Cumulative</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: '3%'}}>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'left'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{startStopHourly}</Card.Title>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="numbers" style={{float: 'right'}}>
                                                        <Card.Title tag="p" style={{fontSize: '20px'}}>{startStopCumulative}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                        </Card.Body>
                    </Card>
                </Col>
            );
        });
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5">
                                <HealthCheckIndicator data={healthCheckData} size="2xs" />
                                {crane.crane_name}
                                <span onClick={loadCraneStatusData}>
                                    <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" size="lg" style={{fontSize: '24px', cursor: 'pointer', paddingLeft: '10px'}} />
                                </span>
                            </Card.Title>
                            {customerName &&
                                <p className="card-category" style={{fontSize: '14px'}}>
                                    {customerName} - {siteName} - {locationName}
                                    {craneSystemName ? ` - ${craneSystemName}` : ''}
                                </p>}
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row>{pageContent}</Row>
        </div>
    )
}
